import React, { PureComponent, useState, useCallback, useEffect } from "react";
import Helmet from "react-helmet"
import Page from "../components/project_d.js"
import IndexSPA from "../components/IndexSPA"

// export default Page

const Index = (props) => {
   return (
      <>
         <Helmet
          title="駐村編輯 / HOME TO ALL 我們也可以這樣生活 / 台北社會住宅藝術計畫"
          meta={[
            {
              name: `description`,
              content: "「駐村」，是藝術能長久深入和地方發生關係的好方法。進駐在地，增廣見聞，累積人脈，並且從中獲得靈感，進一步回饋社區。甚至透過「藝術共創」的方式，製造更大的共鳴。「駐村編輯」的角色誕生於這樣的脈絡中。他是連結並協調進駐藝術家和社群的中介，猶如特派記者，針對地方進行田野調查，熟習產業、市場、和文化習俗，參加節慶地方繞境、特產採收、環境打掃，甚或向職人學習技藝。透過一次又一次親身參與，與當地人「搏感情」，建立非正式的親密連結及信任。除了實體空間以外，還延伸出線上的虛擬網絡與社群連結。有了這些累積，才有機會聚集能量，邀請大家一同從事藝術共創，產生更大的共鳴。",
            },
            {
              property: `og:title`,
              content: "駐村編輯 / HOME TO ALL 我們也可以這樣生活 / 台北社會住宅藝術計畫",
            },
            {
              property: `og:description`,
              content: "「駐村」，是藝術能長久深入和地方發生關係的好方法。進駐在地，增廣見聞，累積人脈，並且從中獲得靈感，進一步回饋社區。甚至透過「藝術共創」的方式，製造更大的共鳴。「駐村編輯」的角色誕生於這樣的脈絡中。他是連結並協調進駐藝術家和社群的中介，猶如特派記者，針對地方進行田野調查，熟習產業、市場、和文化習俗，參加節慶地方繞境、特產採收、環境打掃，甚或向職人學習技藝。透過一次又一次親身參與，與當地人「搏感情」，建立非正式的親密連結及信任。除了實體空間以外，還延伸出線上的虛擬網絡與社群連結。有了這些累積，才有機會聚集能量，邀請大家一同從事藝術共創，產生更大的共鳴。",
            },
            // {
            //   property: `og:url`,
            //   content: 'https://online.home-to-all.com' + window.location.pathname,
            // },
            {
              property: `og:image`,
              content: 'https://online.home-to-all.com' + require('../images/project_c/project_c_1_h_1.jpg')
            }
          ]}
         >
         </Helmet>
         <IndexSPA />
      </>
   )
 };
 
 // Index.propTypes = {}
 
 export default Index;